import fields from "./fields.json";
import widget from "./factory";
import icon from "../../images/goal-widget-icon.svg";
import baseFields from "../baseFields.json";
import { GoalWidget } from "./widget";

export default {
  title: "هدف حمایت",
  id: "DONATION_GOAL",
  icon,
  fields: { ...fields, ...baseFields },
  widget,
  component: GoalWidget,
};
