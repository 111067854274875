import { createRef, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { dragType } from "./DraggableWidget";
import { useDrop } from "react-dnd";
import { classNames } from "../utils/classNames";
import { WidgetRenderer } from "./WidgetRenderer";
import { useWidgets } from "../hooks/useProvideWidgets";
import { QuickActions } from "./QuickActions";
import { BaseWidget } from "../widgets/baseWidget";

export const Editor = () => {
  const {
    widgets,
    setWidgets,
    setSelectedWidgetId,
    selectedWidgetId,
    createWidget,
  } = useWidgets();
  const [width, height] = useWindowSize();
  const [zoom, setZoom] = useState();
  const wrapperRef = createRef<HTMLDivElement>();
  const overlayEditorRef = createRef<HTMLDivElement>();
  const [hasDropped, setHasDropped] = useState(false);

  useEffect(() => {
    function removeWidgetShortcut(e: KeyboardEvent) {
      if (e.key === "Delete" || e.key === "Backspace") {
        if ((document as any)?.activeElement?.nodeName !== "BODY") {
          return;
        }

        setWidgets((prevState: BaseWidget & { id: string }[]) =>
          prevState?.filter((w) => w.id !== selectedWidgetId)
        );
      }
    }

    document.addEventListener("keydown", removeWidgetShortcut, false);

    return () => {
      document.removeEventListener("keydown", removeWidgetShortcut, false);
    };
  }, [widgets, selectedWidgetId]);

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: dragType.DONO_WIDGET,
      drop(_item, monitor) {
        _item && createWidget(_item, widgets);
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        setHasDropped(true);
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver(),
      }),
    }),
    [setHasDropped]
  );

  useEffect(() => {
    const wrapperWidth = wrapperRef?.current?.offsetWidth;
    const overlayEditorWidth = overlayEditorRef?.current?.offsetWidth;
    if (
      typeof wrapperWidth === "undefined" ||
      typeof overlayEditorWidth === "undefined"
    ) {
      return;
    }
    const ratio = wrapperWidth / overlayEditorWidth || 1;
    setZoom(ratio);
  }, [width, height]);

  return (
    <div
      className="p-10 mr-[350px] flex items-center justify-center"
      style={{ height: "calc(100vh - 5rem)" }}
    >
      <div id="wrapper" className="mx-16 mb-14" ref={wrapperRef}>
        <div
          className={classNames(
            "border relative",
            isOverCurrent
              ? "border-dashed border-2 bg-indigo-500/10 border-indigo-700 animate-pulse"
              : "bg-editor bg-transparent"
          )}
          style={{
            borderColor: isOverCurrent ? undefined : "#3f3f51",
            width: 1280,
            height: 720,
          }}
          ref={drop}
        >
          <div
            id="overlay-editor"
            className="relative z-20"
            ref={overlayEditorRef}
          >
            {widgets &&
              widgets?.map((payload) => (
                <WidgetRenderer
                  preview={false}
                  key={payload?.id}
                  {...{ ...payload, zoom }}
                />
              ))}
          </div>

          <div
            className="w-full h-full top-0 left-0 absolute z-[8]"
            onClick={(e) => {
              setSelectedWidgetId(null);
            }}
          ></div>
        </div>
      </div>
      <QuickActions />
    </div>
  );
};
