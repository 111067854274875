import { useWidgets } from "../../../../hooks/useProvideWidgets";
import { InputRenderer } from "../../../InputRenderer";
import widgetsList from "../../../../widgets";
import { useRef } from "react";
import { Button } from "../../../Button";
import { CustomWidgetSettings } from "./CustomWidget";
import { SubMenuWidget } from "./SubMenuWidget";

export const Settings = () => {
  const { selectedWidgetId, targetWidget } = useWidgets();

  if (!targetWidget) {
    return <></>;
  }
  const { variables } = targetWidget;
  if (!variables) {
    return <></>;
  }

  const renderWidgetSettings = () => {
    if (!targetWidget && !targetWidget?.variables) {
      return;
    }

    const variablesKeys = Object.keys(variables);

    return (
      <>
        <div className="flex flex-col gap-y-6">
          {variablesKeys.map((key) => {
            // key => eg: "backgroundColor"
            if (!key) {
              return;
            }
            const widgetType = targetWidget?.type; // eg: "DONATION_GOAL"
            const fields = widgetsList[widgetType].fields;
            /*
            backgroundColor : {type: 'colorpicker', label: 'رنگ پس زمینه'}
            color : {type: 'colorpicker', label: 'رنگ متن'}
            */
            const targetField = fields[key]; // eg {type: 'colorpicker', label: 'رنگ پس زمینه'}
            const value = variables[key]; // rgb(255, 60, 119, 1)

            return (
              <InputRenderer
                section="variables"
                key={key}
                variableKey={key}
                value={value}
                field={targetField}
                widgetId={selectedWidgetId}
              />
            );
          })}
        </div>

        {/* Rendering subsettings */}
        <SubMenuWidget variablesKeys={variablesKeys} />

        {/* Rendering custom widget settings */}
        <CustomWidgetSettings />
      </>
    );
  };

  return <div className="relative">{renderWidgetSettings()}</div>;
};
