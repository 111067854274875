import { useDrag } from "react-dnd";

export enum dragType {
  DONO_WIDGET = "DONO_WIDGET",
}

export const DraggableWidget = ({ id, icon, title }) => {
  const [, drag] = useDrag(() => ({
    type: dragType.DONO_WIDGET,
    item: { id },
  }));
  return (
    <div ref={drag}>
      <div className="border border-light-gray/20 px-3 flex items-center rounded-xl flex-col py-5 hover:bg-indigo-600 transition group hover:border-light-gray/0 cursor-move">
        <div className="h-12 flex items-center bg-light-blue/70 rounded-xl px-4 py-2 mb-3 transition group-hover:bg-indigo-500">
          <img src={icon} className="w-full group-hover:brightness-200" />
        </div>
        <span className="text-white/80 text-sm text-center">{title}</span>
      </div>
    </div>
  );
};
