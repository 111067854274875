import avatar from "../images/avatar.png";
import {
  ArrowRightIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { Button } from "./Button";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useWidgets } from "../hooks/useProvideWidgets";
import { Overlay } from "../api/overlay";
import { toast } from "../helpers/toast";
import { useSocket } from "../hooks/useProvideSocket";

export const Header = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { overlayId } = useParams();
  const { widgets } = useWidgets();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { overlayData } = useSocket();

  const handleSaveOverlay = async () => {
    try {
      setLoading(true);
      const payload = {
        widgets: widgets?.map((w) => {
          return {
            id: w?.id,
            type: w?.type,
            metadata: w,
          };
        }),
      };

      if (!overlayId) {
        return;
      }

      const { data: response } = await Overlay.update({
        id: overlayId,
        payload,
      });

      if (!response) {
        return;
      }

      toast({ message: "نمایه با موفقیت ذخیره شد" });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-white flex items-center justify-between h-20 bg-[#16161d] px-8 fixed top-0 w-full z-40">
      <div className="flex items-center gap-x-6">
        <div className="flex items-center">
          <div className="inline-flex p-[3px] rounded-full bg-gradient-to-b from-[#9053FF] to-light-gray">
            <img
              className="h-9 w-9 rounded-full object-cover"
              src="https://static-cdn.jtvnw.net/jtv_user_pictures/f3591dbe4ee3d94b-profile_image-70x70.png"
            />
          </div>
        </div>

        {!editMode && (
          <div className="flex items-center gap-x-2.5">
            <h1>{overlayData?.name}</h1>
            <a
              className="rounded-lg border border-light-blue w-8 h-8 flex items-center justify-center"
              onClick={() => setEditMode((prevState: boolean) => !prevState)}
            >
              <PencilIcon className="w-3 h-3" />
            </a>
          </div>
        )}
        {editMode && (
          <input
            value={overlayData?.name}
            className="text-15px text-white/80 rounded-xl h-12 px-4 bg-[#24242c] placeholder-light-gray w-[280px]"
          />
        )}
      </div>

      <div className="flex gap-x-8">
        <div className="flex items-center gap-x-4">
          <a
            href={`/${overlayId}?key=${searchParams.get("key")}`}
            target="_blank"
          >
            <Button type="secondary">پیش نمایش</Button>
          </a>
          <Button type="primary" loading={loading} onClick={handleSaveOverlay}>
            ذخیره
          </Button>
        </div>
      </div>
    </div>
  );
};
