import { useEffect, useRef } from "react";

export const AudioPlayer = ({ src, autoPlay, volume }) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current.volume = volume / 100;
  }, [volume]);

  return <audio autoPlay={autoPlay} src={src} ref={ref} />;
};
