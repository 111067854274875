import { useEffect, useRef, useState } from "react";
import { WidgetContainer } from "../../components/WidgetContainer";
import "./widget.css";

export const SubathonWidget = (props) => {
  const filled_time = useRef<number>(0);
  const remaining = useRef<number>();
  const dataIndex = useRef<number>(1);
  const [startTimer, setStartTimer] = useState(false);
  const [google, setGoogle] = useState(null);

  const countdown = (timeToGetAdded) => {
    if (remaining?.current === 0 || timeToGetAdded === 0) {
      // timer already ended
      return;
    }

    document.querySelector(".ship").style.transform = `rotate(${50}deg)`;

    // adding a yellow text to indicate new donation added
    const item_to_add = document.createElement("div");
    item_to_add.className = "item";
    item_to_add.innerHTML =
      "+" + new Date(timeToGetAdded * 1000).toISOString().substr(11, 8);

    document.querySelector(".addtimeholder").appendChild(item_to_add);

    setTimeout(
      function (element) {
        element.parentNode.removeChild(element);
      },
      5000,
      item_to_add
    );

    remaining.current = remaining?.current + timeToGetAdded;
  };

  useEffect(() => {
    const handleWidgetLogic: (detail: any) => void = ({ detail }) => {
      const { id, type, amount } = detail.alert;

      if (id === "resetTimer") {
        remaining.current = props?.variables?.minTime;
        setStartTimer(true);
      }

      if (type && !props?.listener?.includes(type)) {
        return;
      }

      if (type === "donation") {
        if (
          amount < props?.variables.tipMin ||
          props?.variables.tipSeconds === 0 ||
          !startTimer
        ) {
          return;
        }
        countdown(parseInt((+props?.variables.tipSeconds * amount) / 1000));
      }
    };

    window.addEventListener("alert", handleWidgetLogic);

    return () => {
      window.removeEventListener("alert", handleWidgetLogic);
    };
  }, [props, startTimer]);

  useEffect(() => {
    const ticking = () => {
      if (typeof remaining.current === "undefined") {
        return;
      }

      const hours = Math.floor(remaining?.current / 3600);
      const minutes = Math.floor((remaining?.current - hours * 3600) / 60);
      const seconds = remaining?.current - hours * 3600 - minutes * 60;

      document.querySelector(".timer .hour").innerHTML =
        (hours < 10 ? "0" : "") + hours;
      document.querySelector(".timer .minute").innerHTML =
        (minutes < 10 ? "0" : "") + minutes;
      document.querySelector(".timer .second").innerHTML =
        (seconds < 10 ? "0" : "") + seconds;

      filled_time.current = filled_time.current + 1;

      if (remaining?.current > 0) {
        remaining.current = remaining.current - 1;
      }
    };

    const clockInterval = setInterval(function () {
      const fullLength = () => remaining?.current + filled_time?.current;

      if (filled_time?.current > fullLength()) {
        return;
      }
      ticking();
    }, 1000);

    return () => {
      clearInterval(clockInterval);
    };
  }, []);

  const options = {
    backgroundColor: {
      fill: "transparent",
      strokeWidth: 0,
    },
    chartArea: {
      backgroundColor: "transparent",
      strokeWidth: 0,
    },
    legend: {
      position: "none",
    },
    hAxis: {
      gridlines: {
        color: "transparent",
      },
      minorGridlines: {
        color: "transparent",
      },
      textStyle: {
        opacity: 0,
      },
      baselineColor: "transparent",
    },
    vAxis: {
      gridlines: {
        color: "transparent",
      },
      minorGridlines: {
        color: "transparent",
      },
      textStyle: {
        opacity: 0,
      },
      baselineColor: "transparent",
    },
    crosshair: {
      color: "transparent",
      opacity: 0,
    },
    pointsVisible: false,
    tooltip: {
      showColorCode: false,
    },
    lineWidth: 9,
    series: {
      0: { color: "#ffd742" },
    },
  };

  useEffect(() => {
    if (!google) {
      const head = document.head;
      let script = document.getElementById("googleChartsScript");
      if (!script) {
        script = document.createElement("script");
        script.src = "https://www.gstatic.com/charts/loader.js";
        script.id = "googleChartsScript";
        script.onload = () => {
          if (window.google && window.google.charts) {
            window.google.charts.load("current", {
              packages: ["corechart", "line"],
            });

            window.google.charts.setOnLoadCallback(() =>
              setGoogle(window.google)
            );
          }
        };
        head.appendChild(script);
      } else if (window.google) {
        setGoogle(window.google);
      }
    }

    return () => {
      let script = document.getElementById("googleChartsScript");
      if (script) {
        script.remove();
      }
    };
  }, [google]);

  useEffect(() => {
    if (google && startTimer) {
      const data = google.visualization.arrayToDataTable([
        ["x", "d"],
        [0, parseInt(props?.variables?.minTime)],
      ]);

      // Instantiate and draw our chart, passing in some options.
      let chart = new google.visualization.LineChart(
        document.getElementById("time-chart")
      );
      setInterval(function () {
        if (filled_time?.current === 0 || remaining?.current === 0) {
          return;
        }

        console.log("filled time", filled_time?.current);
        console.log("remaining time", remaining?.current);

        data.addRow([filled_time?.current, remaining?.current]);

        if (data.Wf.length > 120) {
          data.removeRow(0);
        }

        chart.draw(data, options);
        console.log(dataIndex?.current, data);
        if (typeof dataIndex.current === "undefined") {
          return;
        }
        dataIndex.current = dataIndex?.current + 1;
      }, 1000);
    }
  }, [google, startTimer]);

  return (
    <WidgetContainer {...props}>
      <div className="overflow-hidden h-full">
        <div className="mainholder">
          <div className="timer">
            <div className="hour">00</div>
            <div className="seperator">:</div>
            <div className="minute">00</div>
            <div className="seperator">:</div>
            <div className="second">00</div>
          </div>
          <div className="addtimeholder" />
          <div className="clockholder">
            <div className="star-field">
              <div className="stars stars-sm" />
              <div className="stars stars-md" />
              <div className="stars stars-lg" />
            </div>

            <div className="time-chart" id="time-chart" />
            <div className="ship">
              <div className="wrapper">
                <div className="body side left" />
                <div className="body main">
                  <div className="wing left" />
                  <div className="wing right" />
                  <div className="booster" />
                  <div className="exhaust" />
                  <div className="window" />
                </div>
                <div className="body side right" />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </WidgetContainer>
  );
};
