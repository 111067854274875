import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const userToken = () => "Bearer " + cookies?.get("access_token");

const errorHandler = (error) => {
  console.error(`${error?.response?.data?.message || "مشکلی پیش آمد."}`);

  return Promise.reject({ ...error });
};

const instance = axios.create({
  baseURL: `https://api.dono.gg/platform/v1`,
  timeout: 60000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

instance.interceptors.request.use(
  function (config) {
    if (cookies?.get("access_token")) {
      (config.headers as any)["Authorization"] = userToken();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const { get, post, put, delete: remove, patch } = instance;

export { get, post, put, remove, patch };
