import { useEffect, useRef } from "react";

export const VideoPlayer = ({ src, autoPlay, volume, ...props }) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current.volume = volume / 100;
  }, [volume]);

  return <video autoPlay={autoPlay} src={src} ref={ref} {...props} />;
};
