import { useWidgets } from "../hooks/useProvideWidgets";
import widgetsList from "../widgets";
import { BellIcon, PlusIcon } from "@heroicons/react/solid";
import { broadcastPayload, useSocket } from "../hooks/useProvideSocket";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../utils/classNames";

export const QuickActions = () => {
  const { widgets, createWidget } = useWidgets();
  const { socket } = useSocket();

  const emulate = () => {
    const payload = `{"alert":{"id":"d0b502cc-291f-4db1-a6ca-d5cef5703373","type":"donation","amount":"4998","createdAt":"2023-04-29T11:25:25.151Z"}}`;
    socket.emit("test_notifications", payload, (res: any) => {
      broadcastPayload(res);
    });
  };

  return (
    <div
      className="fixed bottom-8 left-0 flex w-full z-[9]"
      style={{ direction: "ltr" }}
    >
      <div className="mr-80 flex justify-center grow relative">
        {/* <div
          className="w-12 h-12 bg-indigo-600 flex items-center justify-center shadow-lg shadow-indigo-600/40 cursor-pointer rounded-l-xl"
          onClick={() => createWidget({ id: "DONATION_GOAL" })}
        >
          <PlusIcon className="w-4 h-4 text-white" />
        </div> */}

        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className="w-12 h-12 bg-indigo-600 flex items-center justify-center shadow-lg shadow-indigo-600/40 cursor-pointer rounded-l-xl"
              // onClick={() => emulate()}
            >
              <PlusIcon className="w-4 h-4 text-white" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 bottom-16 -mt-12 w-[300px] origin-top-right grid grid-cols-2 gap-4 p-4 rounded-md overflow-hidden bg-[#24242c] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {true &&
                  Object.keys(widgetsList).map((widgetId) => {
                    const { title, icon, id } = widgetsList[widgetId];

                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => createWidget({ id })}
                            className={classNames(
                              "text-right cursor-pointer"
                              // active && "bg-indigo-600"
                            )}
                            style={{
                              direction: "rtl",
                            }}
                          >
                            <div className="bg-light-blue/40 px-3 flex items-center rounded-xl flex-col py-5 hover:bg-indigo-600 transition group hover:border-light-gray/0">
                              <div className="h-12 flex items-center bg-light-blue/70 rounded-xl px-4 py-2 mb-3 transition group-hover:bg-indigo-500">
                                <img
                                  src={icon}
                                  className="w-full group-hover:brightness-200"
                                />
                              </div>
                              <span className="text-white/80 text-sm text-center">
                                {title}
                              </span>
                            </div>{" "}
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
                {/* {widgets?.length === 0 && (
                  <div
                    className="w-full text-right col-span-2 opacity-80"
                    style={{ direction: "rtl" }}
                  >
                    از سمت راست صفحه یک ویجت را انتخاب کرده و اضافه کنید.
                  </div>
                )} */}
              </Menu.Items>
            </Transition>
          </div>
        </Menu>

        <div
          className="w-12 h-12 bg-[#24242c] flex items-center justify-center shadow-lg shadow-16161d cursor-pointer rounded-r-xl"
          onClick={() => emulate()}
        >
          <BellIcon className="w-4 h-4 text-white" />
        </div>
      </div>
    </div>
  );
};
