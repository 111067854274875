import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { SidebarSection } from "./sections";
import { Layers } from "./sections/Layers";
import { Settings } from "./sections/Settings";
import { Css } from "./sections/Css";
import { Tts } from "./sections/Tts";
import { Text } from "./sections/Text";
import { MenuContext } from "../../layouts/EditorPage";

export const menuPreset = [
  {
    title: "لایه ها",
    id: "layers",
    expanded: false,
    alwaysShow: true,
    Component: <Layers />,
    global: true,
  },
  {
    title: "تنظیمات",
    id: "variables",
    expanded: true,
    Component: <Settings />,
    global: true,
  },
  {
    title: "جایگاه، سایز و استایل",
    id: "css",
    expanded: true,
    Component: <Css />,
    global: true,
  },
  {
    title: "تنظیمات TTS",
    id: "tts",
    expanded: true,
    Component: <Tts />,
    global: false,
  },
  {
    title: "تنظیمات نوشته",
    id: "text",
    expanded: true,
    Component: <Text />,
    global: false,
  },
];

export const SidebarMenu = ({ widgets }) => {
  const [menu, setMenu] = useContext(MenuContext);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (widgets && widgets?.length > 0) {
      setShowMenu(true);
    }
  }, [widgets]);

  return (
    <Transition
      show={showMenu}
      enter="transition duration-150"
      enterFrom="opacity-0 scale-75"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="-mt-6">
        <div className="flex flex-col divide-y divide-light-gray/20">
          {menu.map(
            (menuItem) =>
              menuItem.Component &&
              menuItem.global && (
                <SidebarSection
                  key={menuItem?.id}
                  {...{ ...menuItem, setMenu }}
                />
              )
          )}
        </div>
      </div>
    </Transition>
  );
};
