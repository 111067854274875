import { AlertBoxWidget } from "./types";
import baseWidget from "../baseWidget";

const defaultVariableProps = {
  audio: {
    src: "https://cdn.streamelements.com/static/alertbox/default.ogg",
    volume: 100,
  },
  animation: {
    in: "fadeIn",
    out: "fadeOut",
  },
  graphics: {
    src: "https://cdn.streamelements.com/static/alertbox/ALERT_COINS_converted.webm",
    type: "video",
    volume: 50,
  },
  layout: "horizontal",
  duration: 5,
  enabled: true,
  tts: {
    enabled: false,
    minAmount: 0,
    voice: "Brian",
    volume: 75,
  },
  text: {
    message: "{name} {amount} {currency} حمایت کرد!",
    showMessage: true,
    animation: "pulse",
    css: {
      color: "rgb(255, 255, 255, 1)",
      textAlign: "center",
      fontSize: 18,
      textShadow: "rgba(0, 0, 0, 0.8) 1px 1px 1px",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  variations: {},
};

export const DefaultProps: AlertBoxWidget = {
  css: {
    height: "350px",
    width: "350px",
  },
  variables: {
    tip: { ...defaultVariableProps },
    // sub: { ...defaultVariableProps },
    // cheer: { ...defaultVariableProps },
  },
};

export default (props = DefaultProps) => {
  return {
    ...baseWidget(props),
    type: "ALERTBOX", // TODO: connect it to presets
    variables: props?.variables,
    listener: ["donation"],
    hasSubSettings: true,
  };
};
