import { useWidgets } from "../../../hooks/useProvideWidgets";
import { InputRenderer } from "../../InputRenderer";
import widgetsList from "../../../widgets";
import { useRef } from "react";
import { classNames } from "../../../utils/classNames";

export const Css = () => {
  const { widgets, selectedWidgetId, targetWidget } = useWidgets();

  const renderVariables = () => {
    if (!targetWidget) {
      return;
    }

    const { css } = targetWidget;

    if (!css) {
      return;
    }

    const cssKeys = Object.keys(css);

    return (
      <div className="grid grid-cols-2 gap-6">
        {cssKeys.map((key) => {
          if (!key) {
            return;
          }
          const widgetType = targetWidget?.type;
          const fields = widgetsList[widgetType].fields;
          const targetField = fields[key];
          const value = css[key];

          return (
            <div
              className={classNames(key === "opacity" && "col-span-2")}
              key={key}
            >
              <InputRenderer
                section="css"
                key={key}
                variableKey={key}
                value={value}
                field={targetField}
                widgetId={selectedWidgetId}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>{targetWidget && targetWidget?.variables && renderVariables()}</div>
  );
};
