export type BaseWidget = {
  visible: boolean;
  css: {
    height: string;
    left: string;
    opacity: number;
    top: string;
    width: string;
    "z-index": number;
  };
};

const defaultProps = {
  css: {
    left: "0px",
    top: "0px",
    width: "100px",
    height: "100px",
    opacity: 100,
    zIndex: 1,
  },
};

export default (p) => {
  const props = { css: { ...defaultProps.css, ...p.css } };

  return {
    visible: true,
    css: {
      height: props.css.height,
      left: props.css.left,
      width: props.css.width,
      top: props.css.top,
      opacity: props.css.opacity,
      "z-index": props.css.zIndex,
    },
  };
};
