import fields from "./fields.json";
import widget from "./factory";
import icon from "../../images/goal-widget-icon.svg";
import baseFields from "../baseFields.json";
import { SubathonWidget } from "./widget";

export default {
  title: "ساباتون",
  id: "SUBATHON",
  icon,
  fields: { ...fields, ...baseFields },
  widget,
  component: SubathonWidget,
};
