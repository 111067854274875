import { classNames } from "../../utils/classNames";
import goalImage from "../../images/goal.svg";
import { WidgetContainer } from "../../components/WidgetContainer";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useAuth } from "../../hooks/useProvideAuth";

export const GoalWidget = (props) => {
  const { goal: goalData } = useAuth();
  const [current, setCurrent] = useState<number>();
  const [goal, setGoal] = useState<number>();
  const [lastDono, setLastDono] = useState({ amount: 0 });
  const [showLastDono, setShowLastDono] = useState(false);

  useEffect(() => {
    if (!goalData) {
      return;
    }
    const { progressAmount, maxAmount } = goalData;
    if (!progressAmount || !maxAmount) {
      return;
    }
    setCurrent(+progressAmount);
    setGoal(+maxAmount);
  }, [goalData]);

  useEffect(() => {
    const handleWidgetLogic: (detail: any) => void = ({ detail }) => {
      const alert = detail?.alert;
      if (!alert) {
        return;
      }

      const { id, type, amount } = alert || {};

      console.log("props.listener", props.listener);

      if (type && type !== props.listener) {
        return;
      }
      if (!amount) {
        return;
      }
      console.log("reached here");
      setLastDono({ amount });
    };

    window.addEventListener("alert", handleWidgetLogic);
  }, []);

  useEffect(() => {
    if (!lastDono?.amount) {
      return;
    }
    setShowLastDono(true);
    setCurrent((prevCurrent) => prevCurrent + +lastDono?.amount);
    setTimeout(() => setShowLastDono(false), 1000);
  }, [lastDono]);

  return (
    <WidgetContainer {...props}>
      <div
        className="rounded-2xl py-5 px-6 flex flex-col justify-center h-full overflow-hidden"
        style={{
          backgroundColor: props?.variables?.backgroundColor,
          color: props?.variables?.color,
        }}
      >
        <div className="w-full h-full z-10 absolute top-0 left-0" />
        <div
          className={classNames(
            "flex flex-col gap-y-4 justify-between h-full max-h-32",
            props?.variables?.simplified && "items-center"
          )}
        >
          <h2 className="font-semibold text-base">{goalData?.title}</h2>
          <Transition
            show={showLastDono}
            as={Fragment}
            enter="transition duration-150"
            enterFrom="opacity-0 scale-75 -translate-y-10"
            enterTo="opacity-100 scale-100"
            leave="transition-opacity transition-scale duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 -translate-y-10"
          >
            <div
              className=" absolute top-3 left-6 text-xl flex items-center justify-center fa-num z-40 bg-black/10 rounded-xl py-1 px-4 backdrop-blur"
              style={{ direction: "ltr" }}
            >
              + {lastDono?.amount?.toLocaleString()}
            </div>
          </Transition>
          <div
            className={classNames(
              "overflow-hidden h-5 bg-white/40 rounded-full",
              props?.variables?.simplified ? "w-full" : "ml-18"
            )}
          >
            <div
              className="h-full rounded-full text-black text-sm text-center flex items-center justify-center font-bold transition-all"
              style={{
                backgroundColor: props?.variables?.progressBarColor,
                width: `${(current / goal) * 100}%`,
              }}
            >
              {props?.variables?.showPercentage && (
                <span className="fa-num">{`${((current / goal) * 100)?.toFixed(
                  2
                )}%`}</span>
              )}
            </div>
          </div>
          <span className="fa-num text-sm opacity-80">
            {current?.toLocaleString()} از {goal?.toLocaleString()} تومان
          </span>
        </div>
        {!props?.variables?.simplified && (
          <div className="absolute -left-16 mt-4">
            <img src={goalImage} />
          </div>
        )}
      </div>
    </WidgetContainer>
  );
};
