import { classNames } from "../utils/classNames";
import { Fragment } from "react";
import { Rnd } from "react-rnd";

import { Transition } from "@headlessui/react";
import { useWidgets } from "../hooks/useProvideWidgets";
import { useWidgetInputChange } from "../hooks/useWidgetInputChange";

export const WidgetContainer = (props) => {
  const { setSelectedWidgetId, selectedWidgetId } = useWidgets();

  const { handleInputChange: handleTop } = useWidgetInputChange({
    section: "css",
    inputId: "top",
    widgetId: props.id,
    type: "resizer",
  });
  const { handleInputChange: handleLeft } = useWidgetInputChange({
    section: "css",
    inputId: "left",
    widgetId: props.id,
    type: "resizer",
  });
  const { handleInputChange: handleWidth } = useWidgetInputChange({
    section: "css",
    inputId: "width",
    widgetId: props.id,
    type: "resizer",
  });
  const { handleInputChange: handleHeight } = useWidgetInputChange({
    section: "css",
    inputId: "height",
    widgetId: props.id,
    type: "resizer",
  });

  const active = selectedWidgetId === props?.id;

  const preview = props?.preview;

  return preview ? (
    <div
      className="relative widget-container"
      style={{
        position: "absolute",
        display: "inline-block",
        top: "0px",
        left: "0px",
        width: props?.css?.width,
        height: props?.css?.height,
        transform: `translate(${props?.css?.left}, ${props?.css?.top})`,
      }}
    >
      <div className="widget-content h-full">{props.children}</div>
    </div>
  ) : (
    <Rnd
      size={{ width: props?.css?.width, height: props?.css?.height }}
      position={{
        x: +props?.css?.left.replace("px", ""),
        y: +props?.css?.top.replace("px", ""),
      }}
      onDrag={(e, d) => {
        handleTop(`${d.y}px`);
        handleLeft(`${d.x}px`);
      }}
      onResize={(e, direction, ref, delta, position) => {
        handleWidth(ref.style.width);
        handleHeight(ref.style.height);
        handleTop(`${position.y}px`);
        handleLeft(`${position.x}px`);
      }}
      bounds=".bg-editor"
      className="relative widget-container"
      onMouseDown={() => setSelectedWidgetId(props?.id)}
    >
      <div
        className={classNames(
          "border absolute top-0 left-0 -mt-1 -ml-1 rounded-2xl",
          active
            ? "border-indigo-600/80 shadow-indigo-600/20 shadow-lg"
            : "border-light-gray/20"
        )}
        style={{
          width: `calc(${props?.css?.width} + 8px)`,
          height: `calc(${props?.css?.height} + 8px)`,
        }}
      />
      <Transition
        appear={true}
        show={true}
        as={Fragment}
        enter="transition duration-150"
        enterFrom="opacity-0 scale-75"
        enterTo="opacity-100 scale-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="widget-content h-full">
          <div
            className="h-full"
            style={{ opacity: props?.css?.opacity / 100 }}
          >
            {props.children}
          </div>
        </div>
      </Transition>
    </Rnd>
  );
};
