import { DonationGoalWidget } from "./types";
import baseWidget from "../baseWidget";

export const DefaultProps: DonationGoalWidget = {
  css: {
    height: "135px",
    width: "350px",
  },
  variables: {
    title: "کمک کنید پیشرفت کنم",
    currency: "IRT",
    showPercentage: false,
    simplified: false,
    backgroundColor: "rgb(255, 60, 119, 1)",
    color: "rgb(255, 255, 255, 1)",
    progressBarColor: "rgb(255, 255, 255, 1)",
  },
};

export default (props = DefaultProps) => {
  return {
    ...baseWidget(props),
    type: "DONATION_GOAL", // TODO: connect it to presets
    variables: props?.variables,
    listener: "donation",
  };
};
