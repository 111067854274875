import goalWidget from "./goal-widget";
import customWidget from "./custom-widget";
import alertBoxWidget from "./alert-box-widget";
import subathonWidget from "./subathon-widget";

export default {
  ALERTBOX: alertBoxWidget,
  DONATION_GOAL: goalWidget,
  CUSTOM_REACT: customWidget,
  SUBATHON: subathonWidget,
};
