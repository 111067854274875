import React, { useState, useEffect, useContext, createContext } from "react";
import widgetsList from "../widgets";
import { v4 as uuidv4 } from "uuid";
const widgetContext = createContext<any>();

export function ProvideWidgets({ children }: { children: any }) {
  const auth = useProvideWidgets();
  return (
    <widgetContext.Provider value={auth}>{children}</widgetContext.Provider>
  );
}

export const useWidgets = () => {
  return useContext(widgetContext);
};

function useProvideWidgets() {
  const [widgets, setWidgets] = useState([]);
  const [activeSection, setActiveSection] = useState();
  const [selectedWidgetId, setSelectedWidgetId] = useState<string>();
  const [selectedVariation, setSelectedVariation] = useState<number>();

  const createWidget = ({ id: widgetType }: { id: string }) => {
    if (
      typeof widgetsList === "undefined" &&
      typeof widgetsList[widgetType] === "undefined"
    ) {
      return;
    }
    const uuid = uuidv4();
    const widgetBootstrap = widgetsList[widgetType]?.widget();
    setWidgets((prevState) => {
      console.log("prevstate", prevState);
      setSelectedWidgetId(uuid);

      return [...prevState, ...[{ id: uuid, ...widgetBootstrap }]];
    });
  };

  const [targetWidget] =
    (widgets &&
      widgets.length > 0 &&
      widgets.filter((widget) => widget?.id === selectedWidgetId)) ||
    [];

  return {
    widgets,
    setWidgets,
    selectedWidgetId,
    setSelectedWidgetId,
    targetWidget,
    activeSection,
    setActiveSection,
    selectedVariation,
    setSelectedVariation,
    createWidget,
  };
}
