import { WidgetContainer } from "../../components/WidgetContainer";
import { broadcastPayload, useSocket } from "../../hooks/useProvideSocket";

import { Fragment, useEffect, useRef, useState } from "react";

export const CustomWidget = (props) => {
  const ref = useRef<any>(null);
  const { socket } = useSocket();

  useEffect(() => {
    const broadcast = (alert) => {
      ref.current.contentWindow.postMessage(alert?.detail);
    };

    window.addEventListener("alert", broadcast);

    window.addEventListener("events", broadcast);

    window.addEventListener(
      "message",
      function (e: {
        data: {
          type: string;
          payload: any;
        };
      }) {
        const { data } = e;
        if (data?.type !== "widget-emit") {
          return;
        }

        const payload = data?.payload;

        socket.emit("events", payload);
      }
    );

    return () => {
      window.removeEventListener("alert", broadcast);
      window.removeEventListener("events", broadcast);
    };
  }, []);

  console.log(props.variables.fields);

  return (
    <WidgetContainer {...props}>
      <iframe
        className="h-full w-full"
        loading="eager"
        scrolling="no"
        ref={ref}
        srcDoc={`<html>

<script crossorigin src="https://unpkg.com/react@17/umd/react.production.min.js"></script>
<script crossorigin src="https://unpkg.com/react-dom@17/umd/react-dom.production.min.js"></script>
<script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
<script src="https://cdn.tailwindcss.com"></script>
<script src="https://cdn.jsdelivr.net/npm/socket.io@3.1.2/client-dist/socket.io.min.js"></script>

<script type="text/javascript">
window.fields = ${JSON.stringify(props.variables.memory)}
</script>

<script type="text/jsx">
    ${props.variables.js}
</script>
<style>
    ${props.variables.css}
</style>
<div id="root" />
</html>`}
      />
    </WidgetContainer>
  );
};
