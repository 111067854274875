import { useEffect, useRef, useState } from "react";

export const TtsPlayer = ({ voice, text, volume }) => {
  const [audioUrl, setAudioUrl] = useState();
  const ref = useRef<any>();

  const ttsConfig = {
    url: "https://cors-anywhere.herokuapp.com/https://lazypy.ro/tts/proxy.php",
  };

  const ttsVoices = {
    Brian: {
      service: "Polly",
      voice: "Brian",
    },
  };

  useEffect(() => {
    (async () => {
      const formData = new FormData();
      const { service, voice: _voice } =
        (voice && ttsVoices[voice]) || ttsVoices[Object.keys(ttsVoices)[0]];
      formData.append("service", service);
      formData.append("voice", _voice);
      formData.append("text", text);

      const req = await fetch(ttsConfig.url, {
        method: "POST",
        body: formData,
      });

      const { speak_url } = await req.json();
      if (!speak_url) {
        return;
      }
      setAudioUrl(speak_url);
    })();
  }, []);

  useEffect(() => {
    if (!audioUrl) {
      return;
    }
    ref.current.volume = volume / 100;
  }, [audioUrl]);

  return <>{audioUrl && <audio autoPlay src={audioUrl} ref={ref} />}</>;
};
