import { useEffect, useRef, useState } from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "../utils/classNames";
import { _File } from "../api/file";
import { useWidgets } from "../hooks/useProvideWidgets";
import { MediaType } from "./SidebarMenu/sections/Settings/SubMenuWidget/ActiveSubMenu/settings";
import { useWidgetInputChange } from "../hooks/useWidgetInputChange";
import { MusicNoteIcon } from "@heroicons/react/solid";
import { PlayIcon } from "@heroicons/react/solid";

const UploaderAudioThumbnail = ({ src }) => {
  const ref = useRef<any>();

  return (
    <div>
      <div className="relative">
        <div className="flex items-center justify-center">
          <PlayIcon
            className="w-10 h-10 text-white opacity-80 absolute z-50 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              ref?.current?.play();
            }}
          />
          <MusicNoteIcon className="w-16 h-16 text-light-gray opacity-50" />
        </div>
      </div>
      <audio src={src} ref={ref} />
    </div>
  );
};

export const Uploader = ({
  defaultTab,
  setShowModal,
}: {
  defaultTab?: number;
  setShowModal: (status: boolean) => void;
}) => {
  const { targetWidget, activeSection } = useWidgets();
  const [fileList, setFileList] = useState([
    {
      id: 1,
      name: "test",
      type: "VIDEO",
      url: "https://cdn.streamelements.com/uploads/c86b448b-82c2-4fef-b37e-c95f41052259.webm",
    },
    {
      id: 2,
      name: "test",
      type: "IMAGE",
      url: "https://cdn.discordapp.com/attachments/801855836972515379/1102305441880490124/goal.6a08150c7ebc970e2602f43fcf417036.png",
    },
  ]);
  const [selectedIndex, setSelectedIndex] = useState(defaultTab);
  const tabs = ["تصویر", "ویدیو", "آهنگ"];
  const { handleInputChange: srcUpdater } = useWidgetInputChange({
    section: `variables.${activeSection}.${
      MediaType.IMAGE === selectedIndex || MediaType.VIDEO === selectedIndex
        ? "graphics"
        : "audio"
    }`,
    inputId: "src",
    widgetId: targetWidget?.id,
    type: "uploader",
  });

  const { handleInputChange: typeUpdater } = useWidgetInputChange({
    section: `variables.${activeSection}.graphics`,
    inputId: "type",
    widgetId: targetWidget?.id,
    type: "uploader",
  });

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await _File.getAll();
      if (!data) {
        return;
      }
      setFileList(data);
    })();
  }, []);

  const handleSelectMedia = (url: string) => {
    srcUpdater(url);
    setShowModal(false);
    if (selectedIndex === MediaType.SOUND) {
      return;
    }
    typeUpdater(`${selectedIndex === MediaType.VIDEO ? "video" : "image"}`);
  };

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <Tab.List className="flex mb-6">
        {tabs?.map((tab) => (
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full py-2.5 text-sm leading-5 font-medium text-white rounded-lg",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-secondary ",
                selected
                  ? "bg-indigo-600 shadow"
                  : "text-white hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        <div className="min-h-24">
          <div className="grid grid-cols-5">
            {["IMAGE", "VIDEO", "SOUND"].map((mediaType) => {
              return (
                <>
                  {selectedIndex === MediaType[mediaType] && (
                    <div className="grid grid-cols-5">
                      {fileList &&
                        fileList
                          ?.filter((file) => file?.type === mediaType)
                          ?.map((file) => {
                            const targetObject =
                              targetWidget?.variables[activeSection];
                            const { src } =
                              selectedIndex === MediaType.IMAGE ||
                              selectedIndex === MediaType.VIDEO
                                ? targetObject?.graphics
                                : selectedIndex === MediaType.SOUND
                                ? targetObject?.audio
                                : {};
                            const active = src === file?.url;

                            return (
                              <div
                                className={classNames(
                                  "w-full border border-2 w-24 h-24 p-3 border-light-gray/20 flex items-center justify-center cursor-pointer",
                                  active && "border-indigo-600"
                                )}
                                onClick={() => handleSelectMedia(file?.url)}
                              >
                                {mediaType === "IMAGE" && (
                                  <img src={file?.url} />
                                )}
                                {mediaType === "VIDEO" && (
                                  <video autoPlay loop muted src={file?.url} />
                                )}
                                {mediaType === "SOUND" && (
                                  <UploaderAudioThumbnail src={file?.url} />
                                )}
                              </div>
                            );
                          })}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </Tab.Panels>
    </Tab.Group>
  );
};
