import "./App.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ProvideWidgets } from "./hooks/useProvideWidgets";
import { ProvideSocket } from "./hooks/useProvideSocket";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { EditorPage } from "./layouts/EditorPage";
import { ProvideAuth } from "./hooks/useProvideAuth";
import { Preview } from "./components/Preview";

function App() {
  return (
    <div className="h-full">
      <DndProvider backend={HTML5Backend}>
        <ProvideWidgets>
          <ProvideSocket>
            <ProvideAuth>
              <Router>
                <Routes>
                  <Route path="/editor/:overlayId" element={<EditorPage />} />
                  <Route path="/:overlayId" element={<Preview />} />
                </Routes>
              </Router>
            </ProvideAuth>
          </ProvideSocket>
        </ProvideWidgets>
      </DndProvider>
    </div>
  );
}

export default App;
