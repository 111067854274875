import { WidgetContainer } from "../../components/WidgetContainer";
import { Transition } from "@headlessui/react";
import defaultAlertBg from "../../images/alert-box-bg.svg";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../utils/classNames";
import { AudioPlayer } from "../../components/AudioPlayer";
import { VideoPlayer } from "../../components/VideoPlayer";
import { TtsPlayer } from "../../components/TtsPlayer";
const reactStringReplace = require("react-string-replace");

export const AlertBoxWidget = (props) => {
  const [events, setEvents] = useState<any[]>([]);
  const [event, setEvent] = useState([]);

  const eventsTranslation = {
    donation: "tip",
  };

  const eventData = event.length > 0 && event[0];

  const getEventConfig = () => {
    const baseConfig = props?.variables[eventsTranslation[event[0]?.type]];

    const { variations } = baseConfig || {};

    if (typeof variations === "undefined") {
      return;
    }

    if (Object.keys(variations).length === 0) {
      return baseConfig;
    }

    const sortedVariations = Object.values(variations).sort(
      (a, b) => b?.requirement - a?.requirement
    );
    console.log(sortedVariations);
    console.log("amount", +eventData?.amount);
    const matchedAmount = sortedVariations?.find(
      (variation) => +eventData?.amount >= variation?.requirement
    );
    console.log("matched", matchedAmount);
    if (matchedAmount) {
      return matchedAmount?.settings;
    } else {
      return baseConfig;
    }
  };

  const eventConfig = event.length !== 0 && event[0]?.type && getEventConfig();

  console.log("config", eventConfig);

  useEffect(() => {
    const handleWidgetLogic: (detail: any) => void = ({ detail }) => {
      const { type } = detail;
      if (type && !props?.listener?.includes(type)) {
        return;
      }

      setEvents((prevState) => {
        console.log("prevstate", prevState);
        return [...prevState, detail?.alert];
      });
    };

    window.addEventListener("alert", handleWidgetLogic);
  }, []);

  useEffect(() => {
    console.log("events", events);
    if (typeof events === "undefined") {
      return;
    }

    if (events.length === 0) {
      return;
    }

    if (event.length === 0) {
      const [newEvent] = events;
      setEvent([newEvent]);
    }
  }, [events]);

  useEffect(() => {
    console.log("event", event);
    if (event.length === 0) {
      return;
    }
    console.log("eventConfig", eventConfig);
    if (!eventConfig) {
      return;
    }
    const playEvent = setTimeout(() => {
      setEvent([]);
      setTimeout(() => {
        setEvents((prevEvents) => {
          if (typeof prevEvents === "undefined") {
            return prevEvents;
          }
          const newEvents = [...prevEvents];
          newEvents.shift();
          return newEvents;
        });
      }, 500);
    }, +eventConfig?.duration * 1000);

    return () => {
      clearTimeout(playEvent);
    };
  }, [event]);

  const currencies = {
    IRT: "تومان",
  };

  const renderMessage = (message: string) => {
    const [{ amount, donator }] = event;
    const mapObj = {
      "{amount}": `<b>${amount?.toLocaleString()}</b>`,
      "{currency}": "تومان", // currencies[metadata?.currency] || metadata?.currency
      "{name}": donator || "کاربر ناشناس",
    };

    return message?.replace(
      /{amount}|{currency}|{name}/gi,
      (matched: string) => mapObj[matched]
    );
  };

  const message = (messageObject) => (
    <>
      {messageObject && (
        <h2
          className="fa-num first-char-persian w-full"
          style={{ textAlign: text?.css?.textAlign }}
          dangerouslySetInnerHTML={{ __html: renderMessage(messageObject) }}
        ></h2>
      )}
    </>
  );

  const { layout, graphics, text, audio, tts } =
    (event.length !== 0 &&
      event[0]?.type &&
      typeof eventsTranslation[event[0]?.type] !== "undefined" &&
      eventConfig) ||
    {};
  //console.log(props);

  return (
    <WidgetContainer {...props}>
      <Transition
        show={event.length !== 0}
        as={Fragment}
        enter="transition duration-150"
        enterFrom="opacity-0 scale-75 -translate-y-10"
        enterTo="opacity-100 scale-100"
        leave="transition-opacity transition-scale duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 -translate-y-10"
      >
        <div
          className="h-full w-full flex items-center justify-center px-6 py-3"
          style={{
            textShadow: text?.css?.textShadow,
            fontSize: `${text?.css?.fontSize}px`,
            color: text?.css?.color,
          }}
        >
          {eventConfig && (
            <div
              className={classNames(
                "flex items-center h-full gap-4 w-full overflow-hidden",
                layout === "vertical" && "flex-col justify-center",
                layout === "overlay" && "justify-center"
              )}
            >
              <div
                className={classNames(
                  layout === "horizontal" && "h-full",
                  layout === "vertical" && "h-32",
                  layout === "overlay" &&
                    "absolute z-[-1] w-full h-full top-0 left-0"
                )}
              >
                <AudioPlayer autoPlay src={audio?.src} volume={audio?.volume} />

                {graphics?.type === "image" ? (
                  <img
                    src={graphics?.src}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <VideoPlayer
                    src={graphics?.src}
                    autoPlay
                    className={classNames(
                      "h-full",
                      layout === "overlay" && "w-full"
                    )}
                    volume={graphics?.volume}
                  />
                )}
              </div>
              {tts && tts?.enabled && eventData?.amount > tts?.minAmount && (
                <TtsPlayer
                  voice={tts?.voice}
                  text={eventData?.message}
                  volume={tts?.volume}
                />
              )}
              <div
                className={classNames(
                  "w-full relative",
                  layout === "horizontal" && "flex-grow"
                )}
                style={{
                  top: +text?.css?.marginTop || undefined,
                  bottom: +text?.css?.marginBottom || undefined,
                }}
              >
                {message(text?.message)}
                {text?.showMessage && (
                  <p style={{ textAlign: text?.css?.textAlign }}>
                    {eventData?.message}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </Transition>
    </WidgetContainer>
  );
};
