import widgetsList from "../widgets";

export const WidgetRenderer = (props) => {
  const Component = widgetsList[props?.type]?.component || <></>;
  return (
    <Component
      {...{
        ...props,
        preview: props?.preview,
      }}
    />
  );
};
