import { useWidgets } from "../hooks/useProvideWidgets";
import { useWidgetInputChange } from "../hooks/useWidgetInputChange";
import { SketchPicker } from "react-color";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import _Slider from "react-input-slider";
import { classNames } from "../utils/classNames";
import { MenuAlt2Icon, MenuAlt3Icon, MenuIcon } from "@heroicons/react/solid";
import { Button } from "./Button";
import { broadcastPayload, useSocket } from "../hooks/useProvideSocket";
import { useAuth } from "../hooks/useProvideAuth";
import { Goal } from "../api/goal";

const Align = ({ variableKey, field, widgetId, value, section }) => {
  const { handleInputChange } = useWidgetInputChange({
    section,
    inputId: variableKey,
    widgetId,
    type: field?.type,
  });

  const alignClass = {
    className: "w-4 h-4",
  };

  const alignPreset = [
    {
      id: "right",
      icon: <MenuAlt3Icon {...alignClass} />,
    },
    {
      id: "center",
      icon: <MenuIcon {...alignClass} />,
    },

    {
      id: "left",
      icon: <MenuAlt2Icon {...alignClass} />,
    },
  ];

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-15px text-light-gray">{field?.label}</label>
      <div className="flex gap-x-2">
        {alignPreset?.map(({ icon, id }) => {
          const active = value === id;
          return (
            <div
              className={classNames(
                "rounded-xl flex items-center justify-center p-3 bg-secondary cursor-pointer",
                active && "bg-light-gray/30 text-white",
                !active && "ring ring-1 ring-light-gray/20"
              )}
              onClick={() => handleInputChange(id)}
            >
              {!active ? (
                <div className="opacity-80">{icon}</div>
              ) : (
                <div>{icon}</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Layout = ({ variableKey, field, widgetId, value, section }) => {
  const { handleInputChange } = useWidgetInputChange({
    section,
    inputId: variableKey,
    widgetId,
    type: field?.type,
  });

  const layoutPreset = [
    {
      id: "vertical",
      icon: require("../images/layout-vertical.png"),
    },
    {
      id: "horizontal",
      icon: require("../images/layout-horizontal.png"),
    },
    {
      id: "overlay",
      icon: require("../images/layout-overlay.png"),
    },
  ];

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-15px text-light-gray">{field?.label}</label>
      <div className="grid grid-cols-3 gap-x-3">
        {layoutPreset?.map(({ icon, id }) => {
          const active = value === id;
          return (
            <div
              className={classNames(
                active && "gradient rounded-xl",
                "cursor-pointer p-0.5"
              )}
              onClick={() => handleInputChange(id)}
            >
              <div
                className={classNames(
                  "border border-light-gray/20 rounded-xl flex items-center justify-center h-20 py-5 px-2 bg-secondary"
                )}
              >
                <img src={icon} className="max-h-full fit-cover" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Slider = ({ variableKey, field, widgetId, value, section }) => {
  const { handleInputChange } = useWidgetInputChange({
    section,
    inputId: variableKey,
    widgetId,
    type: field?.type,
  });

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-15px text-light-gray">{field?.label}</label>
      <_Slider
        axis="x"
        x={+value}
        style={{ width: "100%" }}
        xreverse
        onChange={({ x }) => handleInputChange(x)}
        styles={{
          active: {
            backgroundColor: "rgb(67 56 202)",
          },
          track: {
            backgroundColor: "#282834",
          },
        }}
      />
    </div>
  );
};

const ColorPicker = ({ variableKey, field, widgetId, value, section }) => {
  const [showPicker, setShowPicker] = useState(false);
  const { handleInputChange } = useWidgetInputChange({
    section,
    inputId: variableKey,
    widgetId,
    type: field?.type,
  });

  return (
    <div className="flex flex-col gap-3">
      <label
        htmlFor={variableKey}
        className="text-15px text-light-gray flex-grow cursor-pointer"
      >
        {field?.label}
      </label>

      <div className="flex items-center gap-x-2 relative">
        <div className="absolute top-8 z-10" style={{ direction: "ltr" }}>
          {showPicker && (
            <div className="select-none">
              <div
                className="h-screen w-screen fixed top-0 left-0 z-10"
                onClick={() => setShowPicker((prevState) => !prevState)}
              ></div>
              <div className="z-20 relative">
                <SketchPicker
                  onChange={({ rgb }) =>
                    handleInputChange(
                      `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
                    )
                  }
                  color={value}
                  presetColors={[]}
                />
              </div>
            </div>
          )}
        </div>

        <div
          className="border-2 w-6 h-6 flex justify-center items-center rounded-full cursor-pointer"
          style={{ borderColor: value }}
          onClick={() => setShowPicker((prevState) => !prevState)}
        >
          <div
            className="w-3 h-3 rounded-full"
            style={{ backgroundColor: value }}
          ></div>
        </div>
        <input
          type="text"
          value={value}
          onChange={(e) => handleInputChange(e.target.value)}
          className="bg-transparent h-4 border-0 px-0 focus:outline-none focus:ring-0 text-right text-sm opacity-80"
          style={{ direction: "ltr" }}
        />
      </div>
    </div>
  );
};
const CheckBox = ({ variableKey, field, widgetId, value, section }) => {
  const { handleInputChange } = useWidgetInputChange({
    section,
    inputId: variableKey,
    widgetId,
    type: field?.type,
  });

  return (
    <div className="flex items-center gap-2 cursor-pointer">
      <input
        id={variableKey}
        type="checkbox"
        className="bg-light-blue border-0 rounded-6px hover:checked:bg-indigo-600 focus:checked:bg-indigo-600 focus:checked:ring-offset-secondary focus:ring-offset-secondary checked:bg-indigo-500 ring-indigo-500 focus:ring-indigo-500"
        value={value}
        checked={value}
        onChange={handleInputChange}
      />
      <label
        htmlFor={variableKey}
        className="text-15px text-light-gray flex-grow cursor-pointer"
      >
        {field?.label}
      </label>
    </div>
  );
};

const TextInput = ({ variableKey, field, widgetId, value, section, type }) => {
  const { handleInputChange } = useWidgetInputChange({
    section,
    inputId: variableKey,
    widgetId,
    type: field?.type,
  });

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-15px text-light-gray">{field?.label}</label>
      <input
        type={type === "number" ? "number" : "text"}
        className="bg-light-blue border-0 rounded-xl h-12 px-4"
        value={value}
        onChange={handleInputChange}
      />
    </div>
  );
};

const GoalTextInput = ({
  variableKey,
  field,
  widgetId,
  value,
  section,
  type,
}) => {
  const { goal, setGoal } = useAuth();
  const [goalTitle, setGoalTitle] = useState(goal?.title);
  const [timeoutId, setTimeoutId] = useState<any>();

  const debounceAndChangeTitle = async (e) => {
    const value = e.target.value;

    setGoalTitle(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      const { data } = await Goal.update({
        title: value,
      });

      if (!data) return;
      setGoal(data);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-15px text-light-gray">{field?.label}</label>
      {goal && (
        <input
          type={type === "number" ? "number" : "text"}
          className="bg-light-blue border-0 rounded-xl h-12 px-4"
          value={goalTitle}
          onChange={debounceAndChangeTitle}
        />
      )}
    </div>
  );
};

const WidgetButton = ({
  variableKey,
  field,
  widgetId,
  value,
  section,
  type,
}) => {
  const { socket } = useSocket();

  const handleMessageEmit = () => {
    if (!socket) {
      return;
    }

    socket.emit("events", {
      alert: {
        id: variableKey,
        type: "widget-button",
        value,
      },
    });
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Button type="primary" onClick={handleMessageEmit}>
        {field?.label}
      </Button>
    </div>
  );
};

export const InputRenderer = (props) => {
  const fieldType = props?.field?.type; // text, number, checkbox, colorpicker, slider, layout, align, button

  if (fieldType === "text") {
    return <TextInput {...props} />;
  }

  if (fieldType === "text_custom:goal") {
    return <GoalTextInput {...props} />;
  }

  if (fieldType === "number") {
    return <TextInput {...props} type="number" />;
  }

  if (fieldType === "checkbox") {
    return <CheckBox {...props} />;
  }

  if (fieldType === "colorpicker") {
    return <ColorPicker {...props} ref={props.ref} />;
  }

  if (fieldType === "slider") {
    return <Slider {...props} ref={props.ref} />;
  }

  if (fieldType === "layout") {
    return <Layout {...props} />;
  }

  if (fieldType === "align") {
    return <Align {...props} />;
  }

  if (fieldType === "button") {
    return <WidgetButton {...props} />;
  }

  return <></>;
};
