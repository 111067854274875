import { useWidgets } from "../../../hooks/useProvideWidgets";
import widgetsList from "../../../widgets";
import { InputRenderer } from "../../InputRenderer";

export const Text = () => {
  const {
    widgets,
    selectedWidgetId,
    targetWidget,
    activeSection,
    selectedVariation,
  } = useWidgets();

  const renderVariables = () => {
    if (!targetWidget) {
      return;
    }

    const hasSubSections = targetWidget?.hasSubSettings;

    const { text } =
      (hasSubSections
        ? typeof selectedVariation !== "undefined"
          ? targetWidget?.variables[activeSection]?.variations[
              selectedVariation
            ]?.settings
          : targetWidget?.variables[activeSection]
        : targetWidget) || {};

    if (typeof text === "undefined") {
      return;
    }

    const textKeys = Object.keys(text?.css);

    return (
      <div className="grid gap-6">
        {textKeys.map((key) => {
          if (!key) {
            return;
          }
          const widgetType = targetWidget?.type;
          const fields = widgetsList[widgetType]?.fields["text"]["css"];
          if (!fields) {
            return;
          }
          const targetField = fields[key];
          const value = text?.css[key];

          return (
            <>
              <InputRenderer
                section={
                  hasSubSections
                    ? `variables.${activeSection}.text.css`
                    : "text.css"
                }
                key={key}
                variableKey={key}
                value={value}
                field={targetField}
                widgetId={selectedWidgetId}
              />
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div>{targetWidget && targetWidget?.variables && renderVariables()}</div>
  );
};
