import { ChevronDownIcon } from "@heroicons/react/outline";
import { classNames } from "../../../utils/classNames";
import { useWidgets } from "../../../hooks/useProvideWidgets";

export const SidebarSection = ({
  title,
  id,
  expanded,
  setMenu,
  alwaysShow,
  Component,
}) => {
  const { targetWidget, activeSection } = useWidgets();

  const handleExpand = () =>
    setMenu((prevState) =>
      prevState?.map((menu) => {
        if (menu?.id === id) {
          return { ...menu, expanded: !menu?.expanded };
        }

        return menu;
      })
    );

  if (!targetWidget && !alwaysShow) {
    return <></>;
  }

  return (
    <div className="-mx-9 py-4">
      <div className="px-6 flex flex-col">
        <div className="flex justify-between items-center">
          <h2 className="opacity-80">{title}</h2>
          <div
            className="w-9 h-9 rounded-xl bg-light-blue flex items-center justify-center cursor-pointer"
            onClick={handleExpand}
          >
            <ChevronDownIcon
              className={classNames(
                "w-4 h-4 opacity-80",
                !expanded && "rotate-180"
              )}
            />
          </div>
        </div>
        {expanded && <div className="py-6">{Component}</div>}
      </div>
    </div>
  );
};
