import { useWidgets } from "../../../../../hooks/useProvideWidgets";
import { Fragment, useEffect, useState } from "react";
import { ActiveSubMenu } from "./ActiveSubMenu";
import { sectionDefinition } from "./config/sectionDefinition";

export const SubMenuWidget = ({ variablesKeys }) => {
  const { targetWidget, selectedWidgetId, activeSection, setActiveSection } =
    useWidgets();
  const widgetType = targetWidget?.type;
  const hasSubSettings = targetWidget?.hasSubSettings;

  useEffect(() => {
    return () => {
      setActiveSection(null);
    };
  }, []);

  return (
    <>
      {hasSubSettings && (
        <>
          {activeSection ? (
            <ActiveSubMenu
              targetSection={targetWidget?.variables[activeSection]}
              includeVariations
              fixed
            />
          ) : (
            <div className="flex flex-col gap-y-3">
              {variablesKeys.map((subSection) => {
                const section =
                  sectionDefinition[widgetType] &&
                  sectionDefinition[widgetType][subSection];

                return (
                  <div
                    className="bg-dark-blue/80 rounded-xl px-4 py-3 flex gap-x-4 items-center cursor-pointer"
                    onClick={() => setActiveSection(subSection)}
                    key={section?.title}
                  >
                    <div className="w-7 h-7 fill-white bg-secondary flex items-center justify-center rounded-lg">
                      <div className="w-4">{section?.icon}</div>
                    </div>
                    <span className="capitalize">
                      {section?.title || `هشدار ${subSection}`}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};
