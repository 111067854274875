import { useWidgets } from "../../../hooks/useProvideWidgets";
import widgetsList from "../../../widgets";
import { classNames } from "../../../utils/classNames";
import { DocumentIcon } from "@heroicons/react/solid";
import { DocumentIcon as DocumentIconOutline } from "@heroicons/react/outline";

export const Layers = () => {
  const { widgets, selectedWidgetId, setSelectedWidgetId } = useWidgets();

  return (
    <div className="flex flex-col gap-y-4">
      {widgets &&
        widgets.map((widget) => {
          const active = selectedWidgetId === widget?.id;
          return (
            <div
              onClick={() => setSelectedWidgetId(widget?.id)}
              className={classNames(
                "rounded-lg h-12 flex items-center px-4 items-center gap-x-1.5 cursor-pointer",
                active ? "bg-indigo-600" : "border-light-gray/20 border"
              )}
            >
              {active ? (
                <DocumentIcon className="h-4 w-4 opacity-80" />
              ) : (
                <DocumentIconOutline className="h-4 w-4 opacity-80" />
              )}
              <span className="opacity-80">
                {widgetsList[widget?.type]?.title}
              </span>
            </div>
          );
        })}
    </div>
  );
};
