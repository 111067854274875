import widget from "./factory";
import icon from "../../images/alert-box-widget-icon.svg";
import baseFields from "../baseFields.json";
import { AlertBoxWidget } from "./widget";
import fields from "./fields.json";

export default {
  title: "آلرت باکس",
  id: "ALERTBOX",
  icon,
  fields: { ...fields, ...baseFields },
  widget,
  component: AlertBoxWidget,
};
