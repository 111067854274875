import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
} from "react";
import { useCookies } from "react-cookie";
import { Gateway } from "../api/gateway";
import { User } from "../api/user";
import { Goal } from "../api/goal";

const AuthContext = createContext<any>("");

export function ProvideAuth({ children }: { children: ReactNode }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const [gateway, setGateway] = useState(null);
  const [user, setUser] = useState(null);
  const [goal, setGoal] = useState<{
    createdAt: Date;
    id: string;
    maxAmount: string;
    progressAmount: string;
    title: string;
  }>();
  const [cookies] = useCookies();

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          return;
        }
        const { data } = await Gateway.get();
        if (!data) {
          return;
        }
        setGateway(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        if (!cookies?.access_token) {
          return;
        }
        const { data } = await Goal.getSelf();
        if (!data) {
          return;
        }
        setGoal(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [cookies?.access_token]);

  useEffect(() => {
    (async () => {
      try {
        if (!cookies?.access_token) {
          return;
        }
        const { data } = await User.getSelf();
        if (!data) {
          return;
        }
        setUser(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [cookies?.access_token]);

  return {
    user,
    gateway,
    goal,
    setGoal,
  };
}
