import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { useWidgets } from "../../../../../hooks/useProvideWidgets";
import { oneDark } from "@codemirror/theme-one-dark";
import { useWidgetInputChange } from "../../../../../hooks/useWidgetInputChange";
import { useState } from "react";

export const JsonEditor = ({ setFinalFields, value }) => {
  const { widgets, selectedWidgetId, targetWidget } = useWidgets();
  const { variables } = targetWidget || {};
  return (
    <div className="ltr text-left" style={{ direction: "ltr" }}>
      <CodeMirror
        value={value}
        height="500px"
        theme={oneDark}
        extensions={[json()]}
        onChange={(value, viewUpdate) => {
          setFinalFields(value);
        }}
      />
    </div>
  );
};
