import { useWidgets } from "./useProvideWidgets";

export const useWidgetInputChange = ({ section, inputId, widgetId, type }) => {
  const { widgets, setWidgets, selectedVariation } = useWidgets();

  const handleInputChange = (e) => {
    let v: string | number;

    if (type === "checkbox") {
      v = e.target.checked;
    } else if (type === "text") {
      v = e.target.value;
    } else if (type === "number") {
      v = +e.target.value;
    } else {
      v = e;
    }

    let newSection: string;

    if (section && typeof selectedVariation !== "undefined") {
      const manualInput = section.includes("#");
      console.log("manual", manualInput);
      const sectionKeys = section?.replace("#", "").split(".");
      const lastSectionKeys = sectionKeys.length > 2 && sectionKeys?.pop();
      newSection = `${
        (sectionKeys.length > 0 && sectionKeys.join(".") + ".") || ""
      }variations.${selectedVariation}${(!manualInput && ".settings") || ""}${
        (lastSectionKeys && `.${lastSectionKeys}`) || ""
      }`;
      console.log(newSection);
    } else {
      newSection = section;
    }

    setWidgets((prevState) =>
      prevState.map((widget) => {
        const matchTarget = widget.id === widgetId;
        if (matchTarget) {
          const isNestedSection = newSection?.split(".").length > 1;
          if (isNestedSection) {
            let obj = {};
            newSection?.split(".").reduce((r, a, currentIndex, array) => {
              let widgetMock = { ...widget };
              for (let i = 0; i <= currentIndex; i++) {
                const prop = array[i];
                let candidate = widgetMock[prop];
                if (typeof candidate !== "undefined") {
                  widgetMock = candidate;
                } else {
                  break;
                }
              }
              if (array.length === currentIndex + 1) {
                r[a] = { ...widgetMock, [inputId]: v };
              } else {
                r[a] = { ...widgetMock };
              }
              return r[a];
            }, obj);
            return {
              ...widget,
              ...obj,
            };
          } else {
            return {
              ...widget,
              ...(newSection && {
                [newSection]: {
                  ...widget[newSection],
                  [inputId]: v,
                },
              }),
            };
          }
        } else {
          return widget;
        }
      })
    );
  };

  return {
    handleInputChange,
  };
};
