import { useModal } from "../../../../../hooks/useModal";
import { Button } from "../../../../Button";
import { useWidgets } from "../../../../../hooks/useProvideWidgets";
import { CustomWidgetContainer } from "./Container";
import { InputRenderer } from "../../../../InputRenderer";

export const CustomWidgetSettings = () => {
  const { targetWidget } = useWidgets();
  const { Modal, setIsOpen } = useModal({
    closeModal: () => setIsOpen(false),
  });

  const widgetFieldsJson = targetWidget?.variables?.fields;
  console.log(widgetFieldsJson);
  const widgetFields = (widgetFieldsJson && JSON.parse(widgetFieldsJson)) || {};

  console.log(targetWidget);

  return (
    <>
      {targetWidget && targetWidget?.type === "CUSTOM_REACT" && (
        <>
          <div className="absolute">
            <Modal className="max-w-3xl">
              <CustomWidgetContainer setIsOpen={setIsOpen} />
            </Modal>
          </div>

          {Object.keys(widgetFields).map((key) => {
            const field = widgetFields[key];
            return (
              <div className="flex flex-col gap-y-6 mb-6">
                <InputRenderer
                  section="variables.memory"
                  key={key}
                  variableKey={key}
                  value={
                    targetWidget?.variables?.memory &&
                    typeof targetWidget?.variables?.memory[key] !==
                      "undefined" &&
                    targetWidget?.variables?.memory[key] !== null
                      ? targetWidget?.variables?.memory[key]
                      : field?.defaultValue
                  }
                  field={field}
                  widgetId={targetWidget?.id}
                />
              </div>
            );
          })}

          <Button type="primary" onClick={() => setIsOpen(true)}>
            باز کردن ادیتور
          </Button>
        </>
      )}
    </>
  );
};
