import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "../../../../../utils/classNames";
import { HtmlEditor } from "./HtmlEditor";
import { CssEditor } from "./CssEditor";
import { JsonEditor } from "./JsonEditor";
import { JsEditor } from "./JsEditor";
import { Button } from "../../../../Button";
import { useWidgets } from "../../../../../hooks/useProvideWidgets";

export const CustomWidgetContainer = ({ setIsOpen }) => {
  const { setWidgets, targetWidget, selectedWidgetId } = useWidgets();
  const { variables } = targetWidget || {};

  const [css, setCss] = useState(variables?.css);
  const [js, setJs] = useState(variables?.js);
  const [fields, setFields] = useState(variables?.fields);

  let [categories] = useState({
    CSS: <CssEditor setFinalCss={setCss} value={css} />,
    JSX: <JsEditor setFinalJs={setJs} value={js} />,
    FIELDS: <JsonEditor setFinalFields={setFields} value={fields} />,
  });

  const handleUpdateIframe = () => {
    setWidgets((prevState) =>
      prevState.map((widget) => {
        return widget.id === selectedWidgetId
          ? {
              ...widget,
              variables: { ...widget?.variables, css, js, fields },
            }
          : widget;
      })
    );
    setIsOpen(false);
  };

  return (
    <div className="w-full px-2 sm:px-0 mx-auto relative z-80">
      <div className="mb-4">
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-3 bg-secondary/80 rounded-xl">
            {Object.keys(categories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full py-2.5 text-sm leading-5 font-medium text-white rounded-lg",
                    "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-secondary ring-indigo-600 ring-opacity-60",
                    selected
                      ? "bg-indigo-600 shadow text-white"
                      : "text-blue-100 hover:bg-black/[0.12] hover:text-white"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-5">
            {Object.values(categories).map((Component, idx) => (
              <Tab.Panel key={idx}>{Component}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <Button type="primary" block onClick={handleUpdateIframe}>
        ذخیره
      </Button>
    </div>
  );
};
