import { get, post } from "../config/api/user";

type paramsType = {
  page: number;
  limit: number;
  type?: string;
};

export const defaultParams = ({ page, limit }: paramsType) =>
  `?page=${page}&limit=${limit}`;

export const _File = {
  getAll: ({ page, limit } = { page: 1, limit: 40 }) =>
    get(`file/${defaultParams({ page, limit })}`),
  getById: ({ type }: { type: string }) => post(`file?type=${type}`),
};
