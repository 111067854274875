import { useWidgets } from "../../../../../../hooks/useProvideWidgets";
import { Button } from "../../../../../Button";
import React, { useEffect, useState } from "react";
import widgetsList from "../../../../../../widgets";
import { useModal } from "../../../../../../hooks/useModal";
import { ActiveSubMenu } from "./index";
import { InputRenderer } from "../../../../../InputRenderer";
import biblethump from "../../../../../../images/biblethump.png";
import { CogIcon } from "@heroicons/react/solid";

const VariationForm = ({ variationId }) => {
  const {
    targetWidget,
    activeSection,
    setSelectedVariation,
    selectedWidgetId,
  } = useWidgets();

  useEffect(() => {
    return () => {
      setSelectedVariation(undefined);
    };
  }, []);

  const targetSection =
    targetWidget?.variables[activeSection]?.variations[variationId];

  return (
    <div className="overflow-hidden w-full">
      {typeof variationId !== "undefined" && (
        <ActiveSubMenu
          targetSection={targetSection?.settings}
          includeVariations={false}
          append={
            <div className="grid-cols-2 grid gap-x-6">
              <InputRenderer
                section={`#variables.${activeSection}`}
                variableKey={"name"}
                value={targetSection?.name}
                field={widgetsList[targetWidget?.type].fields.name}
                widgetId={selectedWidgetId}
              />

              <InputRenderer
                section={`#variables.${activeSection}`}
                variableKey={"requirement"}
                value={targetSection?.requirement}
                field={widgetsList[targetWidget?.type].fields.requirement}
                widgetId={selectedWidgetId}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

const VariationModal = React.memo(({ selectedVariation }) => {
  const { Modal, setIsOpen } = useModal({
    closeModal: () => setIsOpen(false),
  });

  useEffect(() => {
    if (typeof selectedVariation === "undefined") {
      return;
    }
    setIsOpen(true);
  }, [selectedVariation]);

  return (
    <Modal className="max-w-2xl">
      <VariationForm variationId={selectedVariation} />
    </Modal>
  );
});

export const ActiveSubMenuVariations = () => {
  const {
    targetWidget,
    activeSection,
    setWidgets,
    selectedVariation,
    setSelectedVariation,
  } = useWidgets();
  const variations = targetWidget?.variables[activeSection]?.variations;

  const variationDefaults = {
    tip: {
      //chance: 100,
      condition: "ATLEAST",
      enabled: false,
      name: "حالت جدید",
      requirement: 5,
      type: "amount",
    },
    sub: {
      //chance: 100,
      condition: "ATLEAST",
      enabled: false,
      name: "حالت جدید",
      requirement: 5,
      type: "amount",
    },
  };

  const handleNewVariation = () => {
    const variationId = (variations && Object.keys(variations).length) || 0;
    console.log(variationId);

    setWidgets((prevState) => {
      return prevState?.map((widget) => {
        if (widget?.id === targetWidget?.id) {
          return {
            ...widget,
            variables: {
              ...widget?.variables,
              [activeSection]: {
                ...widget?.variables[activeSection],
                variations: {
                  ...widget?.variables[activeSection]?.variations,
                  [variationId]: {
                    ...variationDefaults[activeSection],
                    name:
                      variationDefaults[activeSection]?.name +
                      ` ${(variationId > 0 && variationId) || ""}`,
                    settings: {
                      ...widget?.variables[activeSection],
                      variations: undefined,
                    },
                  },
                },
              },
            },
          };
        } else {
          return widget;
        }
      });
    });

    setSelectedVariation(variationId);
  };

  return (
    <div>
      {typeof selectedVariation !== "undefined" && (
        <VariationModal selectedVariation={selectedVariation} />
      )}

      {Object.keys(variations).length === 0 ? (
        <div className="flex flex-col gap-y-2 justify-center items-center mb-1">
          <img src={biblethump} className="w-14" />
          <div className="mb-4 text-light-gray text-15px">
            هیچ حالت شرطی تعریف نکرده اید.
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-y-4 mb-5">
          {Object.keys(variations)?.map((variationId) => (
            <div
              className="rounded-lg bg-light-blue py-3.5 px-4 flex justify-between items-center cursor-pointer"
              onClick={() => setSelectedVariation(variationId)}
            >
              <div className="flex flex-col opacity-80">
                {variations[variationId]?.name}
                <span className="text-xs opacity-50">
                  برای{" "}
                  {variations[variationId]?.condition === "ATLEAST" && "حداقل"}{" "}
                  مبلغ {variations[variationId]?.requirement}
                </span>
              </div>
              <CogIcon className="text-light-gray w-5 h-5" />
            </div>
          ))}
        </div>
      )}
      <Button type="primary" onClick={handleNewVariation} block>
        + افزودن شرط جدید
      </Button>
    </div>
  );
};
