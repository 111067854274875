import widget from "./factory";
import icon from "../../images/custom-widget-icon.svg";
import baseFields from "../baseFields.json";
import { CustomWidget } from "./widget";

export default {
  title: "ویجت شخصی",
  id: "CUSTOM_REACT",
  icon,
  fields: { ...baseFields },
  widget,
  component: CustomWidget,
};
