import { XIcon } from "@heroicons/react/outline";
import widgetsList from "../../../../../../widgets";
import { InputRenderer } from "../../../../../InputRenderer";
import { useWidgets } from "../../../../../../hooks/useProvideWidgets";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { SidebarSection } from "../../../index";
import { menuPreset } from "../../../../index";
import { ActiveSubMenuSettings } from "./settings";
import { ActiveSubMenuVariations } from "./variations";
import { classNames } from "../../../../../../utils/classNames";

export const ActiveSubMenu = ({
  targetSection,
  includeVariations,
  fixed = false,
  append,
}) => {
  const {
    targetWidget,
    selectedWidgetId,
    activeSection,
    setActiveSection,
    selectedVariation,
  } = useWidgets();
  const [menu, setMenu] = useState<any[]>();
  const sectionKeys = Object.keys(targetSection);
  const widgetType = targetWidget?.type;

  const renderInlineSettings = (section: string) => {
    if (!targetWidget && !targetSection) {
      return;
    }

    return (
      <div className="flex flex-col gap-y-6">
        {sectionKeys &&
          sectionKeys.map((key) => {
            if (!key) {
              return;
            }
            const fields = widgetsList[widgetType].fields;
            const targetField = fields[key];
            const value = targetSection[key];

            return (
              <InputRenderer
                section={section}
                key={key}
                variableKey={key}
                value={value}
                field={targetField}
                widgetId={selectedWidgetId}
              />
            );
          })}
      </div>
    );
  };

  const renderGroupSections = () => {
    if (!targetWidget && !targetSection) {
      return;
    }
    const groupSectionKeys = sectionKeys.filter(
      (key) =>
        targetSection[key] && targetSection[key].constructor.name === "Object"
    );
    if (!menu) {
      const menuFromGroupSections = groupSectionKeys?.map((gKey) => ({
        id: gKey,
        expanded: false,
      }));
      return setMenu(menuFromGroupSections);
    }
    return groupSectionKeys?.map((gKey) => {
      const [sectionDefinition] = menuPreset.filter(
        (menu) => menu?.id === gKey
      );
      if (!sectionDefinition) {
        return;
      }
      const [{ expanded }] = menu?.filter((m) => m.id === gKey);
      return (
        <SidebarSection
          key={gKey}
          title={sectionDefinition?.title}
          id={sectionDefinition?.id}
          Component={sectionDefinition?.Component}
          expanded={expanded}
          setMenu={setMenu}
        />
      );
    });
  };

  const renderInlineGroupSections = () => {
    const excludedGroups = ["audio", "graphics"];

    if (!targetWidget && !targetSection) {
      return;
    }
    return excludedGroups?.map((gKey) => {
      return Object.keys(targetSection[gKey])?.map((key) => {
        return key;
      });
    });
  };

  return (
    <Transition
      appear={true}
      show={true}
      as={Fragment}
      enter="transition duration-150"
      enterFrom="translate-x-80"
      enterTo="translate-0"
      leave="transition duration-150"
      leaveFrom="opacity-100"
      leaveTo="translate-x-80"
    >
      <div
        className={classNames(
          "bg-[#24242c] z-50 overflow-y-auto altered-scrollbar",
          fixed && "fixed top-20 -right-0 w-80 py-2 px-9",
          !fixed && "px-7"
        )}
        style={{ height: fixed ? "calc(100vh - 5rem)" : "undefined" }}
      >
        <div className="flex flex-col divide-y divide-light-gray/20">
          <div className="-mx-9 py-4">
            <div className="px-6">
              <div className="flex justify-between items-center">
                <h2 className="opacity-80">
                  تنظیمات{" "}
                  <span className="capitalize">
                    {selectedVariation ? "شرط جدید" : activeSection}
                  </span>
                </h2>
                <div
                  className="w-9 h-9 rounded-xl bg-light-blue flex items-center justify-center cursor-pointer"
                  onClick={() => setActiveSection(null)}
                >
                  <XIcon className="w-4 h-4 opacity-80" />
                </div>
              </div>
              <div className="py-6">
                <div className="flex flex-col gap-y-6">
                  {append}

                  <ActiveSubMenuSettings target={targetSection}>
                    {renderInlineSettings(`variables.${activeSection}`)}
                  </ActiveSubMenuSettings>
                </div>
              </div>
            </div>
          </div>

          {includeVariations && (
            <SidebarSection
              title="حالت شرطی"
              id="variations"
              Component={<ActiveSubMenuVariations />}
              expanded={
                menu?.filter((m) => m?.id === "variations")[0]?.expanded
              }
              setMenu={setMenu}
            />
          )}

          {renderGroupSections()}
        </div>
      </div>
    </Transition>
  );
};
