import { createRef, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { classNames } from "../utils/classNames";
import { WidgetRenderer } from "./WidgetRenderer";
import { useWidgets } from "../hooks/useProvideWidgets";
import { useParams } from "react-router-dom";
import { useSocket } from "../hooks/useProvideSocket";

export const Preview = () => {
  const { widgets, setWidgets, setSelectedWidgetId } = useWidgets();
  const [width, height] = useWindowSize();
  const [zoom, setZoom] = useState();
  const wrapperRef = createRef<HTMLDivElement>();
  const overlayEditorRef = createRef<HTMLDivElement>();
  const { overlayId } = useParams();
  const { overlayData } = useSocket();

  useEffect(() => {
    const wrapperWidth = wrapperRef?.current?.offsetWidth;
    const overlayEditorWidth = overlayEditorRef?.current?.offsetWidth;
    if (
      typeof wrapperWidth === "undefined" ||
      typeof overlayEditorWidth === "undefined"
    ) {
      return;
    }
    const ratio = wrapperWidth / overlayEditorWidth || 1;
    setZoom(ratio);
  }, [width, height]);

  useEffect(() => {
    ["body", "html"].forEach((el) =>
      (
        document.querySelector(el) as HTMLBodyElement | HTMLElement
      )?.style.setProperty("background", "transparent")
    );
  }, []);

  useEffect(() => {
    if (!overlayData?.metadata) {
      return;
    }

    const { widgets } = overlayData?.metadata;

    if (!widgets) {
      return;
    }

    const normalizedOverlayData = widgets?.map(
      (o: { metadata: any }) => o?.metadata
    );

    setWidgets(normalizedOverlayData);
  }, [overlayData]);

  return (
    <div className="flex items-center justify-center">
      <div id="wrapper" ref={wrapperRef}>
        <div
          className={classNames("relative")}
          style={{
            width: 1280,
            height: 720,
          }}
        >
          <div
            id="overlay-editor"
            className="relative z-20"
            ref={overlayEditorRef}
          >
            {widgets &&
              widgets?.map((payload) => (
                <WidgetRenderer
                  preview
                  key={payload?.id}
                  {...{ ...payload, zoom }}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
