import { CustomWidget } from "./types";
import baseWidget from "../baseWidget";

export const DefaultProps: CustomWidget = {
  css: {
    height: "300px",
    width: "300px",
  },
  variables: {
    html: "<h1>hello world</h1>",
    css: "body,html { margin:0; padding:0 }",
    js:
      "const App = () => {\n" +
      "  const [alerts, setAlerts] = React.useState([]);\n" +
      "  \n" +
      "  React.useEffect(() => {\n" +
      '    window.addEventListener("message", function (e) {\n' +
      "      const {data} = e;\n" +
      "      setAlerts((prevAlerts) => [...prevAlerts, data])\n" +
      "    });\n" +
      "  }, [])\n" +
      "  \n" +
      "  return (\n" +
      '    <div className="flex items-center justify-center h-screen p-3">\n' +
      "      {alerts && alerts.map((alert) => {\n" +
      "        return (\n" +
      '          <div className="px-4 py-3 bg-white/5 w-full rounded-xl">\n' +
      '            <div className="flex justify-between text-white">\n' +
      "              <span>{alert?.donator}</span>\n" +
      "              <span>{alert?.amount}</span>\n" +
      "            </div>\n" +
      "          </div>\n" +
      "        )\n" +
      "      })}\n" +
      "    </div>\n" +
      "  )\n" +
      "}\n" +
      "\n" +
      "ReactDOM.render(<App />, document.getElementById('root'));",
    fields:
      "{\n" +
      '  "minTime": {\n' +
      '    "type": "number",\n' +
      '    "label": "تایم پیش فرض ساباتون"\n' +
      "  }\n" +
      "}",
    memory: {},
  },
};

export default (props = DefaultProps) => {
  return {
    ...baseWidget(props),
    type: "CUSTOM_REACT", // TODO: connect it to presets
    variables: props?.variables,
  };
};
