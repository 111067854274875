import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

type ModalProps = {
  title?: string;
  description?: string;
  fullWidth?: boolean;
  closeModal: any;
};

export const useModal = ({
  title,
  description,
  fullWidth,
  closeModal,
}: ModalProps) => {
  let [isOpen, setIsOpen] = useState(false);

  return {
    Modal: ({ children, className }) => (
      <div className="w-full h-full">
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 overflow-y-auto"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/30 z-90" />
            </Transition.Child>

            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  className={`inline-block w-full p-6 my-8 text-right align-middle transition-all transform shadow-xl rounded-2xl overflow-hidden bg-secondary z-50 ${
                    className || "max-w-md"
                  }`}
                >
                  {title && (
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  {description && (
                    <div className="mt-2">
                      <p className="text-15px text-white/50">{description}</p>
                    </div>
                  )}
                  <div>
                    {(title || description) && <div className="mt-4" />}
                    {children}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    ),
    setIsOpen,
  };
};
