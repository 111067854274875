import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { useWidgets } from "../../../../../hooks/useProvideWidgets";
import { oneDark } from "@codemirror/theme-one-dark";

export const JsEditor = ({ setFinalJs, value }) => {
  return (
    <div className="ltr text-left" style={{ direction: "ltr" }}>
      <CodeMirror
        value={value}
        height="500px"
        theme={oneDark}
        extensions={[javascript({ jsx: true })]}
        onChange={(value, viewUpdate) => {
          setFinalJs(value);
        }}
      />
    </div>
  );
};
