import { patch, get } from "../config/api/user";

export const Overlay = {
  update: ({ id, payload }: { id: string; payload: any[] }) =>
    patch(`overlay/${id}`, {
      metadata: payload,
    }),
  getById: ({ id }: { id: string }) => get(`overlay/${id}`),
  getConfigById: ({ id }: { id: string }) => get(`overlay/${id}/config`),
};
