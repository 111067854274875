import transparency from "../../../../../../images/transparency.png";
import {
  MusicNoteIcon,
  PhotographIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { InputRenderer } from "../../../../../InputRenderer";
import widgetsList from "../../../../../../widgets";
import { useWidgets } from "../../../../../../hooks/useProvideWidgets";
import { useState } from "react";
import { useModal } from "../../../../../../hooks/useModal";
import { Uploader } from "../../../../../Uploader";
import { classNames } from "../../../../../../utils/classNames";

export enum MediaType {
  IMAGE,
  VIDEO,
  SOUND,
}

export const ActiveSubMenuSettings = ({ target, children }) => {
  const {
    targetWidget,
    selectedWidgetId,
    activeSection,
    setActiveSection,
    selectedVariation,
  } = useWidgets();
  const widgetType = targetWidget?.type;
  const { Modal, setIsOpen: setShowModal } = useModal({
    closeModal: () => setShowModal(false),
  });
  const [uploaderDefaultTab, setUploaderDefaultTab] = useState(0);

  const handleOpenUploader = (tabId) => {
    setShowModal(true);
    setUploaderDefaultTab(tabId);
  };

  return (
    <>
      <div className="absolute">
        <Modal className="max-w-2xl">
          <Uploader
            setShowModal={setShowModal}
            defaultTab={uploaderDefaultTab}
          />
        </Modal>
      </div>

      {target && (
        <>
          <div className="flex flex-col gap-y-3">
            <span className="text-15px text-light-gray">تصویر هشدار</span>
            <div
              className={classNames(
                "flex items-center",
                !selectedVariation && "justify-between",
                selectedVariation && "gap-x-6"
              )}
            >
              <div
                className="w-32 h-28 overflow-hidden flex items-center justify-center"
                style={{ backgroundImage: `url(${transparency})` }}
              >
                {target?.graphics?.type === "video" ? (
                  <video
                    autoPlay
                    loop="loop"
                    src={target?.graphics?.src}
                    muted
                  />
                ) : (
                  <img src={target?.graphics?.src} />
                )}
              </div>
              <div className="flex flex-col gap-y-4">
                <div
                  className="border border-light-gray/40 rounded-xl py-2.5 px-3 flex items-center gap-x-2 text-15px opacity-80 cursor-pointer"
                  onClick={() => handleOpenUploader(MediaType.IMAGE)}
                >
                  <PhotographIcon className="w-5 h-5" />
                  افزودن تصویر
                </div>

                <div
                  className="border border-light-gray/40 rounded-xl py-2.5 px-3 flex items-center gap-x-2 text-15px opacity-80 cursor-pointer"
                  onClick={() => handleOpenUploader(MediaType.VIDEO)}
                >
                  <VideoCameraIcon className="w-5 h-5" />
                  افزودن ویدیو
                </div>
              </div>
            </div>
            {target?.graphics?.type === "video" && (
              <InputRenderer
                section={`variables.${activeSection}.graphics`}
                key={"volume"}
                variableKey={"volume"}
                value={target?.graphics?.volume}
                field={widgetsList[widgetType].fields?.graphics?.volume}
                widgetId={selectedWidgetId}
              />
            )}
          </div>

          <hr className="border-light-gray/20" />

          <div className="flex flex-col">
            <span className="text-15px text-light-gray">صدای هشدار</span>
            <div
              className={classNames(
                "flex items-center",
                !selectedVariation && "justify-between",
                selectedVariation && "gap-x-6"
              )}
            >
              <div className="flex items-center opacity-80 text-15px gap-x-2">
                <div className="truncate w-32">
                  {
                    target?.audio?.src?.split("/")[
                      target?.audio?.src?.split("/").length - 1
                    ]
                  }
                </div>
              </div>
              <div
                className="border border-light-gray/40 rounded-xl py-2.5 px-3 flex items-center gap-x-2 text-15px opacity-80 relative -top-3.5 cursor-pointer"
                onClick={() => handleOpenUploader(MediaType.SOUND)}
              >
                <MusicNoteIcon className="w-5 h-5" />
                تغییر صدا
              </div>
            </div>
            <InputRenderer
              section={`variables.${activeSection}.audio`}
              key={"volume"}
              variableKey={"volume"}
              value={target?.audio?.volume}
              field={widgetsList[widgetType].fields?.audio?.volume}
              widgetId={selectedWidgetId}
            />
          </div>

          {children}

          <div className="flex flex-col gap-y-4">
            <InputRenderer
              section={`variables.${activeSection}.text`}
              key={"message"}
              variableKey={"message"}
              value={target?.text?.message}
              field={widgetsList[widgetType].fields?.text?.message}
              widgetId={selectedWidgetId}
            />
            <InputRenderer
              section={`variables.${activeSection}.text`}
              key={"showMessage"}
              variableKey={"showMessage"}
              value={target?.text?.showMessage}
              field={widgetsList[widgetType].fields?.text?.showMessage}
              widgetId={selectedWidgetId}
            />
          </div>
        </>
      )}
    </>
  );
};
