import _toast from "react-hot-toast";

const styles = {
  success: {
    borderRadius: "10px",
    background: "#24242c",
    color: "#fff",
  },
};

export const toast = ({
  message,
  icon,
  style,
}: {
  message: string;
  icon?: string;
  style?: keyof typeof styles;
}) =>
  _toast(message, {
    icon,
    style: style ? styles[style] : styles["success"],
  });
