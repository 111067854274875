import { createContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Editor } from "../components/Editor";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";
import { menuPreset } from "../components/SidebarMenu";
import { useWidgets } from "../hooks/useProvideWidgets";
import { useSocket } from "../hooks/useProvideSocket";

export const MenuContext = createContext<any>("");
export const OverlayDataContext = createContext<any>("");

export const EditorPage = () => {
  const { selectedWidgetId, setWidgets } = useWidgets();
  const [menu, setMenu] = useState(menuPreset);
  const { overlayData } = useSocket();

  useEffect(() => {
    const noWidgetsAreSelected = !selectedWidgetId;
    if (noWidgetsAreSelected) {
      return setMenu((prevState) =>
        prevState?.map((m) =>
          m.id === "layers" ? { ...m, expanded: true } : m
        )
      );
    }
    // setMenu((prevState) =>
    //   prevState?.map((m) => (m.id === "layers" ? { ...m, expanded: false } : m))
    // );
  }, [selectedWidgetId]);

  useEffect(() => {
    if (!overlayData?.metadata) {
      return;
    }

    const { widgets } = overlayData?.metadata;

    if (!widgets) {
      return;
    }

    const normalizedOverlayData = widgets?.map(
      (o: { metadata: any }) => o?.metadata
    );

    setWidgets(normalizedOverlayData);
  }, [overlayData]);

  return (
    <MenuContext.Provider value={[menu, setMenu]}>
      <OverlayDataContext.Provider value={[overlayData]}>
        <Toaster />
        <div className="pt-20">
          <Header />
          <Editor />
        </div>
        <Sidebar />
      </OverlayDataContext.Provider>
    </MenuContext.Provider>
  );
};
