import { DonationGoalWidget } from "./types";
import baseWidget from "../baseWidget";

export const DefaultProps: DonationGoalWidget = {
  css: {
    height: "235px",
    width: "450px",
  },
  variables: {
    minTime: 3600,
    resetTimer: "Start/Reset Timer",
    sub1Seconds: 120,
    sub2Seconds: 240,
    sub3Seconds: 360,
    cheerMin: 100,
    cheerSeconds: 10,
    tipMin: 1000,
    tipSeconds: 30,
  },
};

export default (props = DefaultProps) => {
  return {
    ...baseWidget(props),
    type: "SUBATHON", // TODO: connect it to presets
    variables: props?.variables,
    listener: ["donation", "widget-button"],
  };
};
