import rash from "../images/rash.svg";
import avatar from "../images/avatar.png";
import shield from "../images/shield.svg";
import { Button } from "./Button";
import { DraggableWidget } from "./DraggableWidget";
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { SidebarMenu } from "./SidebarMenu";
import { useWidgets } from "../hooks/useProvideWidgets";
import widgetsList from "../widgets";
import spaceship from "../images/spaceship.png";
import { classNames } from "../utils/classNames";

export const Sidebar = () => {
  const { widgets } = useWidgets();
  const [showFirstTimeMenu, setShowFirstTimeMenu] = useState(true);

  useEffect(() => {
    if (widgets?.length > 0) {
      setShowFirstTimeMenu(false);
    }
  }, [widgets]);

  return (
    <div
      className="top-20 z-[10] h-full fixed w-80 right-0 top-0 overflow-auto altered-scrollbar bg-[#24242c]"
      style={{ height: "calc(100% - 5rem)" }}
    >
      <div className="h-full">
        <div
          className="w-full h-40 absolute -z-10 top-0 r-0"
          // style={{ backgroundImage: `url(${rash})` }}
        />
        <div
          className={classNames(
            "flex flex-col px-9 h-full",
            widgets?.length > 0 ? "py-8" : "py-10"
          )}
        >
          <div className="flex flex-col justify-between h-full gap-y-16">
            <div>
              <SidebarMenu widgets={widgets} />

              <Transition
                show={showFirstTimeMenu}
                enter="transition duration-150"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="mb-8">
                  <div className="flex items-center mb-4 gap-x-4">
                    <img src={avatar} className="w-10 h-10 rounded-lg hidden" />
                    <span className="font-bold text-lg">سلام،</span>
                  </div>
                  <span className="text-light-gray ">
                    برای شروع یکی از ویجت های زیر رو بکش سمت چپ:
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                  {Object.keys(widgetsList).map((widgetId) => {
                    const { title, icon, id } = widgetsList[widgetId];

                    return (
                      <DraggableWidget
                        key={id}
                        title={title}
                        icon={icon}
                        id={id}
                      />
                    );
                  })}
                </div>
              </Transition>
            </div>
            {widgets?.length === 0 && (
              <div className="flex flex-col items-center gap-y-4 rounded-xl mx-4">
                <div className="hidden">
                  <img src={spaceship} className="w-20" />
                </div>
                <span>نیاز به راهنمایی دارید؟</span>
                <a className="bg-light-blue/70 hover:bg-secondary/80 text-white/70 inline-flex items-center transition rounded-xl px-7 py-3">
                  بیشتر بدانید
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
