import React, { useState, useEffect, useContext, createContext } from "react";
import io from "socket.io-client";
import { socketConfig } from "../config/socket";

const socketContext = createContext<any>("");

export function ProvideSocket({ children }: { children: any }) {
  const auth = useProvideSocket();
  return (
    <socketContext.Provider value={auth}>{children}</socketContext.Provider>
  );
}

export const useSocket = () => {
  return useContext(socketContext);
};

export const broadcastPayload = (payload: any) => {
  try {
    const jsonParsedPayload = JSON.parse(payload);
    if (!jsonParsedPayload) {
      return;
    }
    const e = new CustomEvent("alert", {
      detail: jsonParsedPayload,
    });

    window.dispatchEvent(e);
  } catch (e) {
    console.error(e);
  }
};

function useProvideSocket() {
  const [socket, setSocket] = useState<any>();
  const [overlayData, setOverlayData] = useState<any>();

  const search = window.location.search;
  const overlayKey = search.replace("?key=", "");

  useEffect(() => {
    const { url } = socketConfig;

    const newSocket = io(url, {
      path: "/platform/v1/ws",
      transports: ["websocket"],
      auth: {
        authorization: `WS ${overlayKey}`,
      },
    });
    setSocket(newSocket);
    return () => {
      console.log("disconnecting socket");
      newSocket.close();
    };
  }, [overlayKey, setSocket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const alertListener = (alert) => console.log(alert);

    socket.on("notifications", (detail: any) => {
      console.log("notifications", detail);
      broadcastPayload(detail);
    });

    socket.on("events", (detail: any) => {
      console.log("events", detail);
      broadcastPayload(JSON.stringify(detail));
    });

    socket.on("overlay", (detail: any) => {
      if (!detail) {
        return;
      }

      setOverlayData(detail);
      console.log("detail", detail);
    });

    return () => {
      console.log("removing socket listeners");
      socket.off("event-test", alertListener);
    };
  }, [socket]);

  return {
    socket,
    broadcastPayload,
    overlayData,
  };
}
